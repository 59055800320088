import React from 'react';
import { Helmet } from 'react-helmet'
import { useStaticQuery, graphql } from 'gatsby'
import favicon from '../images/favicon.ico'

const Head = ({ title, description }) => {
    
    const data = useStaticQuery(graphql`
        query {
            site {
                siteMetadata{
                    title
                    description
                }
            }
        }
    `)
     if (description==undefined) {
        description=data.site.siteMetadata.description;
     }   


    return (
       //  <Helmet title={`${title} | ${data.site.siteMetadata.title}`} />
       <Helmet  htmlAttributes={{ lang: 'en' }}>
           
       <title>{`${title} | ${data.site.siteMetadata.title}`}</title>
       <meta name="viewport" content="initial-scale=1, maximum-scale=1"/>

       <meta name="description" content={`${description}`} />
       <link rel="icon" href={favicon} />
     </Helmet>
    )
}

export default Head;