import React, { Fragment } from "react";
import { Link } from 'gatsby'
import { Helmet } from 'react-helmet'
import FeatureImg from "../../images/legallatinpic.jpg"


const LegalQuotesIntro = () => {

    return (
    <Fragment>
       <Helmet>
 
        <meta name="description" content="Latin quotes on different topics and popular Latin expressions, alphabetized and broken down into pages" />

      </Helmet>

     <div>
     <div align="center">
     <img className={"featureImageFloat"} src={FeatureImg} alt="Legal Latin columns"/>
    <p>Latin maxims and legal phrases are sometimes compared to axioms in geometry. These are the established universal principles of law, usually well known to people in the legal profession. However, it must be said that these pages can in no circumstances be used as a source of legal advice, legal counseling and/or simple recommendation on any matter, including but not limited to judicial proceedings of criminal, civic, family, common law and Court TV. By choosing to read the text below you, the reader, <i>scienter </i>relinquish any right to use these maxims for any purpose other than personal use without expressed written consent etc., etc. In other words, folks, if you need a lawyer, get a lawyer. If you need a law degree - get a law degree. Of course, if you happen to be a lawyer, here is your chance to breathe some life into your Latin. If instead of legal maxims you need more general Latin expressions, I have special pages dedicated to mottos, sayings, quotes, slogans etc.
</p>


<p><b><Link  activeClassName ='selected' to="../LegalQuotes_a">A</Link> | <Link  activeClassName ='selected' to="../LegalQuotes_b">B</Link> | <Link  activeClassName ='selected' to="../LegalQuotes_c">C</Link> | <Link  activeClassName ='selected' to="../LegalQuotes_d">D</Link> | <Link  activeClassName ='selected' to="../LegalQuotes_e">E</Link> | <Link  activeClassName ='selected' to="../LegalQuotes_f">F</Link> | <Link  activeClassName ='selected' to="../LegalQuotes_g">G</Link> | <Link  activeClassName ='selected' to="../LegalQuotes_h">H</Link> | <Link  activeClassName ='selected' to="../LegalQuotes_i">I</Link> | <Link  activeClassName ='selected' to="../LegalQuotes_j">J</Link> | <Link  activeClassName ='selected' to="../LegalQuotes_l">L</Link> | <Link  activeClassName ='selected' to="../LegalQuotes_m">M</Link> | <Link  activeClassName ='selected' to="../LegalQuotes_n">N</Link> | <Link  activeClassName ='selected' to="../LegalQuotes_o">O</Link> | <Link  activeClassName ='selected' to="../LegalQuotes_p">P</Link> | <Link  activeClassName ='selected' to="../LegalQuotes_q">Q</Link> | <Link  activeClassName ='selected' to="../LegalQuotes_r">R</Link> | <Link  activeClassName ='selected' to="../LegalQuotes_s">S</Link> | <Link  activeClassName ='selected' to="../LegalQuotes_t">T</Link> | <Link  activeClassName ='selected' to="../LegalQuotes_u">U</Link> | <Link  activeClassName ='selected' to="../LegalQuotes_v">V</Link></b></p>
</div>

     </div>
     </Fragment>
    )
  }
  
  export default LegalQuotesIntro
  