import  React from "react"
import Layout from "../../components/layout"
import { Link } from 'gatsby'
import Head from '../../components/head'
import LatQuotesIntro from './LegalQuotesIntro'

// markup
const LatQuotes_u = () => {
  return (
   <Layout >
      <Head title='Latin Legal Maxims, Writs and Expressions - U'/>
      <h1>Legal Latin maxims and expressions</h1>
     <h2>U</h2>
     <LatQuotesIntro />
     <p><b>Ubi eadem ratio ibi idem jus, et de similibus idem est judicium</b> - When there is the same reason, then the law is the same, and the same judgment should be rendered as to similar things.</p>
<p><b>Ubi jus ibi remedium est</b> - Where there is a right there is a remedy.</p>
<p><b>Ubi non est principalis, non potest esse accessorius</b> - Where there is no principal, there can be no accessory.</p>
<p><b>Ubi nullum matrimonium, ibi nulla dos es</b> - Where there is no marriage, there is no dower.</p>
<p><b>Ultima voluntas testatoris est perimplenda secundum veram intentionem suam</b> - The last will of a testator is to be fulfilled according to his true intentio.</p>
<p><b>Ut poena ad paucos, metus ad omnes, perveniat</b> - That punishment may come to a few, the fear of it should affect all.</p>
<p><b>Utile per inutile non vitiatur</b> - What is useful is not vitiated by the useless.</p>

   </Layout>
  )
}

export default LatQuotes_u
